import * as React from 'react';
import { connect } from 'react-redux';
import LinkedInIcon from '../assets/logos/linkedin.png';


const Home = () => (
    <div>
        <h1><strong>aete consulting</strong></h1>
        <h6>
            <strong>
                <a href="mailto: tobias@aete.se">tobias@aete.se</a>
            </strong>
        </h6>

        <a href="https://www.linkedin.com/in/tsundqvist">
            <img className="mt-2 img-fluid small-icon"
                src={LinkedInIcon}
                alt="logo" />
        </a>
  
        {/*    <img className="mt-2 img-fluid small-icon"*/}
        {/*        src={`${process.env.PUBLIC_URL}/linkedin.png`}*/}
        {/*        alt="logo" />*/}

    </div>
);

export default connect()(Home);
