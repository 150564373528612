import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenu from './NavMenu';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        {/*<NavMenu/>*/}
        <Container fluid={true} >
            <div className="p-5 m-5 bg-light">
                {props.children}
            </div>
            <Row noGutters={true}>
                
            </Row>

            
            {/*<Row noGutters={true}>*/}
            {/*    <Col sm>*/}
               
            {/*    </Col>*/}
            {/*</Row>*/}
        </Container>
    </React.Fragment>
);
